import React from 'react';

import { makeStyles } from '@material-ui/styles';

import Typography from '@material-ui/core/Typography';

//******************************************************************************
const useStyles = makeStyles({
  container: {
    flex: '1 0 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    alignSelf: 'center',
  },
});
//******************************************************************************
export function NotFoundPage(props)
{
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <Typography className={classes.text} color={'secondary'} variant={'h3'}>
        Page not found
      </Typography>
    </div>
  );
}
//------------------------------------------------------------------------------
export default NotFoundPage;
//******************************************************************************
